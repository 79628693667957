<template>
    <section class="formular product-report-dialog">
        <v-card flat>
            <v-card-text class="pa-0 pb-12">
                <template>
                    <v-tabs
                        fixed-tabs height="32"
                        color="gold" :background-color="this.$vuetify.theme.dark ? '#181818FF' : '#EEEEEEFF'"
                        active-class="gold lighten-1 white--text" style="flex: unset; position: sticky; top: 0px; z-index: 1;"
                    >
                        <v-tab @click="fillSelector('products_report_provisions_list_select')">
                            <v-icon left small>mdi-file-multiple-outline</v-icon>{{ $t('products_report.dialog.button_agreements') }}</v-tab>
                        <v-tab @click="fillSelector('packages_report_list_select')">
                            <v-icon left small>mdi-webpack</v-icon>{{ $t('products_report.dialog.button_packages') }}</v-tab>
                    </v-tabs>
                </template>
                <DataSelect v-if="show" :data_view_name="getDataSelect" :filter_push="filterOverride"/>
            </v-card-text>
        </v-card>
    </section>
</template>

<script>
import DataSelect from "@/components/DataSelect";

export default {
    name: "ProductionReportDialog",
    components: {
        DataSelect
    },
    props: {
        edit: Boolean,
        type: String,
        id: undefined,
        card_item: undefined
    },
    data: () => ({

        dialog_detail: false,
        category: 'provisions',
        show: true,
        current_dataselect: 'products_report_provisions_list_select',
        record_id: undefined
    }),
    computed: {
        getCategoryData() {
            return this.category_data;
        },
        getDataSelect() {
            return this.current_dataselect;
        },
        filterOverride() {
            let filter = {};
            filter.record_id = this.id;

            return filter;
        }
    },
    created() {
        let title = this.id + " • " + this.card_item.seller;
        this.$parent.$emit('action', {func: 'setToolbarTitle', data: title});
    },
    methods: {
        fillSelector(_store) {
            this.show = false;
            this.current_dataselect = _store;
        },
    },
    mounted() {
        this.$root.$on('dataselect-done', () => {
            this.show = true;
        });

        this.$root.$on('item-report', (id, _item, _uid) => {
            this.$root.$emit('open-dialog', id, _uid);
        });
    },
    destroyed() {
        this.$root.$off('dataselect-done');
        this.$root.$off('item-report');
    }
}
</script>

<style>
    .product-report-dialog .data-select {
        z-index: 0;
    }
    .product-report-dialog .data-table-toolbar {
        top: 32px;
    }
</style>